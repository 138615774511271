<template>
  <div id="app" v-if="show">
    <transition :name="SkipSwitchName">
      <router-view :key="$route.fullPath"></router-view>
    </transition>
  </div>
</template>

<script>
import userApi from '@/api/user'
import { mapGetters } from 'vuex'
import { returnLang, showId } from '@/utils/validate'

export default {
  name: 'App',

  data() {
    return {
      SkipSwitchName: '',
      show: false
    }
  },
  created() {
    this.getbasic()
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },
  mounted() {
  },
  methods: {
    returnLang,
    async getUserInfo() {
      try {
        const res = await userApi.userInfo()
        this.getbasic(res.DefaultLanguage)
      } catch (error) {
        this.getbasic()
      }
    },
    async getbasic(lang = 'Chinese') {
      let defaultLang = ''
      let localLang = ''
      defaultLang = showId(lang)
      localLang = localStorage.getItem('language') || 'en' // 暂时固定为英文！！！！！！！
      this.$i18n.locale = localLang
      this.$store.commit('SET_LANG', localLang, false)
      localStorage.setItem('language', localLang)
      this.show = true
    }
  }
}
</script>
