import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/utils/rem'
import '@/styles/index.styl'
import 'vant/lib/index.css'
import * as filters from './filters'
import * as echarts from 'echarts'
import store from './store'
import VConsole from 'vconsole'
import VuCountryIntl from 'vue-country-intl'
// import css
import 'vue-country-intl/lib/vue-country-intl.css'
// Global registration component
Vue.component(VuCountryIntl.name, VuCountryIntl)

Vue.prototype.$vconsole = VConsole
Vue.prototype.$echarts = echarts

import VueI18n from 'vue-i18n'
import Vant from 'vant'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

Vue.use(Vant).use(VueI18n).use(ElementUI)

const i18n = new VueI18n({
    locale: localStorage.getItem('language') || 'en', // 语言标识
    messages: {
        zh: require('@/assets/lang/zh'), //繁体中文
        en: require('@/assets/lang/en'), //英文
        jp: require('@/assets/lang/jp'), //日语
        yn: require('@/assets/lang/yn'), //印度尼西亚文
        yuenan: require('@/assets/lang/yuenan'), //越南文
        ty: require('@/assets/lang/ty'), //泰语
        hy: require('@/assets/lang/hy'), //韩语
        gmw: require('@/assets/lang/gmw'), //高棉文
        fy: require('@/assets/lang/fy'), //法语
        xby: require('@/assets/lang/xby'), //西班牙语
        ey: require('@/assets/lang/ey'), //俄语
        pty: require('@/assets/lang/pty'), //葡萄牙语
        dy: require('@/assets/lang/dy'), //德语
    },
    silentTranslationWarn: true
})

// register global utility filters
Object.keys(filters).forEach((key) => {
    Vue.filter(key, filters[key])
})

Vue.config.productionTip = false

new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App)
}).$mount('#app')