module.exports = {
    common: {
        success: '成功',
        input: '请输入',
        lang: '语言',
        comfirm: '确认',
        cancel: '取消',
        passwordCheck: '密码最少六个字符',
        Email: '邮箱',
        Phone: '手机号码',
        PleaseSerach: '请搜索',
        NickName: '昵称',
        ContactInformation: '联系信息',
        Password: '密码',
        Star: '星',
        CopySuccess: '复制成功',
        ReferrerID: '推荐人ID',
        noData: '暂无记录'
    },
    home: {
        Register: '注册',
        DirectPush: '直推',
        Audit: '审核',
        Service: '服务',
        MyTeam: '我的团队',
        Donate: '捐赠',
    },
    register: {
        Send: '发送',
        Resend: '重新发送',
        InvitationCode: '邀请码',
        VerificationCode: '验证码',
        btn: '注册并登录',
        Country: '国家',
        haveAccount: '已有账号',
        haveLogin: '登录',
        EmailError: '邮箱格式错误',
    },
    login: {
        btn: '登录',
        noAccount: '还没有账号',
        toRegister: '注册'
    },
    user: {
        AllOrders: '所有订单',
        PaymentMethod: '收款方式',
        ChangePassword: '修改密码',
        ShareLink: '分享链接',
        ApplicationRecord: '申请记录',
        ContactCustomer: '联系客服',
        SignOut: '退出登录',
        WhetherOut: '是否退出',
        Quit: '退出',
        OldPassword: '旧密码',
        NewPassword: '新密码',
        confirmChanges: '确认更改',
        MyShareCode: '我的分享码',
        CopyShareCode: '复制分享码',
        ShareLimtMsg: '请先升级至5星'
    },
    pay: {
        Wait: '待审核',
        Success: '成功',
        Fail: '失败',
        Auto: '自动',
        Paid: '已支付',
        Audited: '已审核',
        Bank: '银行',
        QRCode: '二维码',
        TRC20: 'TRC20',
        Gratitude: '感恩费',
        ToolUsage: '工具使用费',
        AuditMsg: '审核中，请等待',
        UpgradeMsg: '未达到升级条件',
        ReturnHome: '返回首页',
        GratefulLockMsg: '支付感恩费解除锁定',
        ApplyMsg: '申请升级',
        ApplyMemberMsg: '会员',
        Name: '姓名',
        BankName: '银行名称',
        BankAccount: '银行账号',
        Channel: '渠道',
        Address: '地址',
        SelectQRCode: '选择收款二维码',
        LeaveWord: '留言',
        UpDocument: '上传支付凭证',
        Hubmsg: '请按照所选支付方式完成支付，上传支付凭证后确认提交',
        SubmitApplication: '提交申请',
        SubmissionSuccessful: '提交成功',
        PendingReview: '待审核',
        UpconfirmMsg: '恭喜您提交成功，请等待审核，确认付款成功后可进行升级',
        GratitudeLock: '感恩锁',
        CompleteUnlock: '完成任务解锁',
        GratitudeLockMsg: '请捐款解锁感恩锁，恢复收款',
        ToUnlock: '去解锁',
        wantDonate: '我要捐款',
        donationRules: '自愿捐款规则',
        donationPledge: '自愿捐款承诺',
        readVoluntaryDonated: '我已阅读并愿意自愿捐款',
        paymentMethod: '收款方式',
        TRC20Address: 'TRC20 地址',
        CollectionQRCode: '收款二维码',
        submitInformation: '提交信息',
        AddCollectionQRCode: '添加收款二维码',
        PlatformName: '平台名称',
        ConfirmAdd: '确认添加',
        ShareLockMsg: '需要有两个直推5星，才能继续收款和升级',
        TopStarLimtMsg: '已达到最高星级',
        SetPaymentMsg: '请先设置收款方式',
        UpgradeComentMsg1: '需要有两个直推',
        UpgradeComentMsg2: '而且没有待审核的订单',
    },
    direct: {
        Totalpeople: '总人数',
        Directpush: '直推',
        starAbove: '1星及以上',
        Statistics: '团队统计',
        Connections: '层级人脉',
        Architecture: '架构图',
        MyDirectRecommendation: '我的直推推荐',
        TeamStatistics: '团队统计',
        Ordinarymember: '普通会员',
        starMember: '星级会员',
        people: '人',
        HierarchicalConnections: '层级人脉',
        degreeConnections: '度人脉',
        ArchitectureDiagram: '架构图',
        AllAmount: '总收入',
        GratitudeAmount: '感恩值',
    },
    audit: {
        Entire: '全部',
        Invitation: '邀请',
        Gratitude: '感恩',
        Upgrade: '升级',
        Serachplaceholder: '会员ID、昵称',
        Pass: '通过',
        Fail: '失败',
        PaymentDetails: '支付详情',
        UserID: '用户ID',
        Usernickname: '用户昵称',
        ContactInformation: '联系方式',
        TransferType: '转账类型',
        PaymentAmount: '支付金额',
        PaymentTime: '支付时间',
        PaymentType: '支付方式',
        PaymentDocument: '支付凭证',
        PaymentRemark: '支付备注',
        AuditStatus: '审核状态',
        AuditTime: '审核时间',
        ApplicationRecord: '申请记录',
    }
}