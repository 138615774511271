import moment from 'moment'

// 手机验证
export function rightPhoneNumber(mobile) {
    return /^1\d{10}$/gi.test(mobile)
}
// 获取链接中的参数
export function getUrlParam(item) {
    var svalue = location.hash.match(
        new RegExp('[?&]' + item + '=([^&]*)(&?)', 'i')
    )
    return svalue ? svalue[1] : svalue
}

// 格式修改
export function splitData1(arr) {
    let categoryData = []

    arr.forEach((item) => {
        var obj = {
            time:
                (Date.parse(item.CreateTime.replace(/-/g, '/')) + 8 * 60 * 60 * 1000) /
                1000,
            // time: Date.parse(item.CreateTime.replace(/-/g, '/')) / 1000,
            open: item.Open,
            high: item.High,
            low: item.Low,
            close: item.Close
        }
        categoryData.push(obj)
    })
    return categoryData
}
export function IsreadSysNotice() {
    const flag = sessionStorage.getItem('props')
    if (!flag) {
        sessionStorage.setItem('props', true)
        return true
    } else {
        return false
    }
}
export function getLocalTime(value) {
    return moment(value).local().format('YYYY-MM-DD HH:mm:ss')
}
export function returnLang(lang, setLang = false) {
    let localeLang = ''
    return localeLang
}

const langArr = [{
        Id: 'zh',
        Name: 'Chinese'
    },
    {
        Id: 'ft',
        Name: 'Traditional'
    },
    {
        Id: 'ty',
        Name: 'Thai'
    },
    {
        Id: 'en',
        Name: 'English'
    },
    {
        Id: 'hy',
        Name: 'Korea'
    },
    {
        Id: 'yn',
        Name: 'Indonesia'
    },
    {
        Id: 'jp',
        Name: 'Japanese'
    }
]

/**
 * array show
 * @param {String} string
 */
export function showName(Id) {
    if (Id === 'Error') {
        return '错误'
    } else {
        let name = ''
        langArr.some((item) => {
            if (item.Id === Id) {
                name = item.Name
                return true
            }
        })
        return name
    }
}
/**
 * array show
 * @param {String} string
 */
export function showId(Name) {
    if (Name === 'Error') {
        return '错误'
    } else {
        let Id = ''
        langArr.some((item) => {
            if (item.Name === Name) {
                Id = item.Id
                return true
            }
        })
        return Id
    }
}

export function IsPC() {
    var userAgentInfo = navigator.userAgent
    var Agents = [
        'Android',
        'iPhone',
        'SymbianOS',
        'Windows Phone',
        'iPad',
        'iPod'
    ]
    var flag = true
    for (var i = 0; i < Agents.length; i++) {
        if (userAgentInfo.indexOf(Agents[i]) != -1) {
            flag = false
            break
        }
    }
    return flag
}

export function dataURLtoBlob(dataUrl) {
    const arr = dataUrl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], { type: mime })
}

export function getRandomString(len) {
    len = len || 15
    var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
    var maxPos = $chars.length
    var pwd = ''
    for (var i = 0; i < len; i++) {
        pwd += $chars.charAt(Math.floor(Math.random() * maxPos))
    }
    return pwd
}

export function rTime(date) {
    var json_date = new Date(date).toJSON()
    return new Date(new Date(json_date) + 8 * 3600 * 1000)
        .toISOString()
        .replace(/T/g, ' ')
        .replace(/\.[\d]{3}Z/, '')
}

export function isEmptyObject(obj) {
    if (obj === null || obj === undefined) return true
    for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            return false
        }
    }
    return true
}

export function getLang() {
    var lang = localStorage.getItem('language') || 'en'
    if (lang == 'zh') {
        lang = 'zh-Hant'
    }
    if (lang == 'en') {
        lang = 'en-US'
    }
    if (lang == 'jp') {
        lang = 'ja'
    }
    if (lang == 'hy') {
        lang = 'ko'
    }
    if (lang == 'ty') {
        lang = 'th'
    }
    if (lang == 'yn') {
        lang = 'id'
    }
    if (lang == 'yuenan') {
        lang = 'vi'
    }
    if (lang == 'gmw') {
        lang = 'km'
    }
    if (lang == 'fy') {
        lang = 'fr'
    }
    if (lang == 'xby') {
        lang = 'es'
    }
    if (lang == 'ey') {
        lang = 'ru'
    }
    if (lang == 'pty') {
        lang = 'pt'
    }
    if (lang == 'dy') {
        lang = 'de'
    }
    return lang
}