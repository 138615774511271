import axios from 'axios'
import qs from 'qs'
import { Toast } from 'vant'
import { Token } from '@/utils/mm'
import router from '@/router'

const service = axios.create({
    baseURL: window.baseURL + '/api',
    timeout: 60000
})
service.defaults.headers.post['Content-Type'] = 'application/json'

let loading = null
service.interceptors.request.use(
    (config) => {
        // loading = Toast.loading({
        //   forbidClick: true,
        //   duration: 0
        // })
        const token = 'Bearer ' + Token.get()
        if (token) {
            config.headers['Authorization'] = token
        }

        var lang = localStorage.getItem('language') || 'en'
        if (lang == 'zh') {
            lang = 'zh-CN'
        }
        if (lang == 'en') {
            lang = 'en-US'
        }
        if (lang == 'jp') {
            lang = 'ja'
        }
        if (lang == 'hy') {
            lang = 'ko'
        }
        if (lang == 'ty') {
            lang = 'th'
        }
        if (lang == 'yn') {
            lang = 'id'
        }
        if (lang == 'yuenan') {
            lang = 'vi'
        }
        if (lang == 'gmw') {
            lang = 'km'
        }
        if (lang == 'fy') {
            lang = 'fr'
        }
        if (lang == 'xby') {
            lang = 'es'
        }
        if (lang == 'ey') {
            lang = 'ru'
        }
        if (lang == 'pty') {
            lang = 'pt'
        }
        if (lang == 'dy') {
            lang = 'de'
        }
        config.headers['Accept-Language'] = lang
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    (response) => {
        if (loading) {
            loading.clear()
        }
        const responseCode = response.status
        if (responseCode === 200) {
            return Promise.resolve(response)
        } else {
            return Promise.reject(response)
        }
    },
    (error) => {
        if (loading) {
            loading.clear()
        }
        // 断网 或者 请求超时 状态
        if (!error.response) {
            // 请求超时状态
            if (error.message.includes('timeout')) {
                Toast('The request timed out. Please check whether the network connection is normal')
            } else {
                Toast('The request failed. Please check whether the network is connected')
            }
            return
        }
        // alert(Token.get())
        const responseCode = error.response.status
        switch (responseCode) {
            case 400:
                if (typeof error.response.data.Message === 'string') {
                    Toast(error.response.data.Message)
                } else {
                    Toast(error.response.data)
                }
                break
                // 401 未登录
            case 401:
                Token.remove()
                router.push({ name: 'Login' })
                break
            default:
                Toast('The server is busy, please try again later')
        }
        return Promise.reject(error)
    }
)

/**
 * 封装get请求
 * @param {请求地址} url
 * @param {请求参数} params
 * @returns {Promise}
 */
export function fetch(url, params = {}) {
    return new Promise((resolve, reject) => {
        if (url.indexOf('?') === -1) {
            url += '?t=' + Date.now()
        } else {
            url += 't=' + Date.now()
        }
        service
            .get(url, {
                params: params
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

/**
 * 封装post请求
 * @param {请求地址} url
 * @param {请求参数} data
 */
export function post(url, data = {}) {
    return new Promise((resolve, reject) => {
        service
            .post(url, data)
            .then((res) => {
                resolve(res.data)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

/**
 * 封装上传文件请求
 * @param {请求地址} url
 * @param {请求参数} data
 */
export function uploadFile(url, data = {}) {
    return new Promise((resolve, reject) => {
        service
            .request({
                url: url,
                method: 'post',
                baseURL: '/upload',
                data: qs.stringify(data)
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

/**
 * 封装put请求
 * @param {请求地址} url
 * @param {请求参数} data
 */
export function put(url, data = {}) {
    return new Promise((resolve, reject) => {
        service
            .put(url, data)
            .then((res) => {
                resolve(res.data)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

/**
 * 封装delete请求
 * @param {请求地址} url
 * @param {请求参数} data
 */
export function remove(url, data = {}) {
    return new Promise((resolve, reject) => {
        service
            .delete(url, data)
            .then((res) => {
                resolve(res.data)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

export default service