import Cookies from 'js-cookie'

const tokenKey = 'currency_tk'

export const Token1 = {
  get: () => Cookies.get(tokenKey),
  set: (token) => {
    Cookies.set(tokenKey, token)
  },
  remove: () => {
    Cookies.remove(tokenKey)
  }
}
export const Token = {
  get: () => localStorage.getItem(tokenKey),
  set: async (token) => {
    await localStorage.setItem(tokenKey, token)
  },
  remove: () => {
    localStorage.removeItem(tokenKey)
  }
}

/**
 * LocalStorage
 */
export const Storage = {
  // 存储
  set(key, value) {
    window.localStorage.setItem(key, JSON.stringify(value))
  },
  // 获取
  get(key) {
    return JSON.parse(window.localStorage.getItem(key))
  },
  remove(key) {
    window.localStorage.removeItem(key)
  },
  update(key, modifyKey, value) {
    let data = this.get(key)
    data[modifyKey] = value
    this.set(key, data)
  }
}
