import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

let routes = [{
        path: '/',
        name: 'index',
        meta: { tx: 1 },
        redirect: '/home'
    },
    {
        path: '/home',
        name: 'Home',
        meta: { tx: 1 },
        component: () =>
            import ('@/views/home')
    },
    {
        path: '/direct',
        name: 'Direct',
        component: () =>
            import ('@/views/direct')
    },
    {
        path: '/donate',
        name: 'Donate',
        component: () =>
            import ('@/views/donate')
    },
    {
        path: '/donateAgreement',
        name: 'DonateAgreement',
        component: () =>
            import ('@/views/donate/agreement')
    },
    {
        path: '/donatePlatformPay',
        name: 'DonatePlatformPay',
        component: () =>
            import ('@/views/donate/platformPay')
    },
    {
        path: '/donateUpPay',
        name: 'DonateUpPay',
        component: () =>
            import ('@/views/donate/upPay')
    },
    {
        path: '/audit',
        name: 'Audit',
        component: () =>
            import ('@/views/audit')
    },
    {
        path: '/payment',
        name: 'Payment',
        component: () =>
            import ('@/views/payment')
    },
    {
        path: '/editPassword',
        name: 'EditPassword',
        component: () =>
            import ('@/views/editPassword')
    },
    {
        path: '/applyRecord',
        name: 'ApplyRecord',
        component: () =>
            import ('@/views/applyRecord')
    },
    {
        path: '/msgDetail',
        name: 'MsgDetail',
        meta: { tx: 1 },
        component: () =>
            import ('@/views/msgDetail')
    },
    {
        path: '/user',
        name: 'User',
        meta: { tx: 1 },
        component: () =>
            import ('@/views/user')
    },
    {
        path: '/notice',
        name: 'Notice',
        meta: { tx: 1 },
        component: () =>
            import ('@/views/notice')
    },
    {
        path: '/login',
        name: 'Login',
        meta: { tx: 1 },
        component: () =>
            import ('@/views/login')
    },
    {
        path: '/language',
        name: 'Language',
        meta: { tx: 1 },
        component: () =>
            import ('@/views/lang')
    },
    {
        path: '/register',
        name: 'Register',
        meta: { tx: 2 },
        component: () =>
            import ('@/views/register')
    },
    {
        path: '/iframePage',
        name: 'IframePage',
        meta: { tx: 1 },
        component: () =>
            import ('@/views/iframePage')
    },
    {
        path: '/share',
        name: 'Share',
        meta: { tx: 3 },
        component: () =>
            import ('@/views/share')
    },
    { path: '*', redirect: '/404' }
]
const router = new VueRouter({ routes: routes })

router.afterEach((to) => {
    localStorage.setItem('fullPath', to.fullPath)
})
export default router