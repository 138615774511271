import { fetch, post } from '@/utils/axios'

export default {
    // 获取图片验证码 调用频率限制终端和IP
    getCaptchaImg: (params) => {
        return fetch('/Captcha/img', params)
    },
    // 发送国际短信
    getCaptchaSms: (data) => {
        return post('/Captcha/sms', data)
    },
    // 获取邮箱验证码，生成token，发送验证码并返回
    getCaptchaEmail: (data) => {
        return post('/Captcha/email', data)
    },
    // 用户注册接口
    subAccountRegister: (data) => {
        return post('/Account/register', data)
    },
    // 登录获取刷新token
    getAccountRefresh: (data) => {
        return post('/Account/refresh', data)
    },
    // 通过刷新token 获取授权token
    getAccountToken: (token) => {
        return fetch(`/Account/${token}`)
    },
    // 获取用户基本信息
    getAccountBase: (params) => {
        return fetch('/ex/Account/base', params)
    },
    //修改密码
    editPassword: (data) => {
        return post('/ex/Account/password', data)
    },
    // 修改头像昵称
    editHeadImg: (data) => {
        return post('/ex/Account/headImg', data)
    },
    //获取团队统计信息
    getMyteamStatistics: () => {
        return fetch('/ex/Account/team/statistics')
    },
    //获取直推列表
    getMyPushUserList: (params) => {
        return fetch('/ex/Account/getUserList', params)
    },
    //获取团队人数统计
    getMyteamMemberNum: () => {
        return fetch('/ex/Account/teamMemberNum')
    },
    //获取层级人脉统计
    getMyInvitationMemberNum: () => {
        return fetch('/ex/Account/invitationMemberNum')
    },
    //获取团队层级架构
    getMyteam: () => {
        return fetch('/ex/Account/team')
    },
    //获取我收款的订单列表
    getMyCollection: (params) => {
        return fetch('/ex/Transfer/MyCollection', params)
    },
    // 审核订单
    auditCollection: (data) => {
        return post('/ex/Transfer/audit', data)
    },
    //获取我的收款信息
    getMyCollectionDetail: () => {
        return fetch('/ex/Account/collectionDetail')
    },
    // 更新我的收款信息
    updateCollection: (data) => {
        return post('/ex/Account/update/collection', data)
    },
    //获取我支付的订单列表
    getMyPay: (params) => {
        return fetch('/ex/Transfer/MyPay', params)
    },
    //获取收款信息
    getCollection: (params) => {
        return fetch('/ex/Transfer/collection', params)
    },
    //获取收款用户二维码
    getCollectionQrCode: (params) => {
        return fetch('/ex/Transfer/collectionQrCode', params)
    },
    //获取系统银行卡
    getSystemBank: () => {
        return fetch('/ex/Transfer/systemBank')
    },
    //获取系统收款二维码
    getSystemQrCode: () => {
        return fetch('/ex/Transfer/systemQrCode')
    },
    //创建支付订单
    addPayOrder: (data) => {
        return post('/ex/Transfer/add', data)
    },
    // 获取内容列表详情
    getContentTitles: (type, params) => {
        return fetch(`/Content/${type}/titles`, params)
    },
    // 内容带分页
    getContentFY: (type, params) => {
        return fetch(`/Content/${type}/titles`, params)
    },
    // 获取内容详情
    getContentDetail: (params) => {
        return fetch('/Content/detail', params)
    },
    // 上传文件
    getCaptchaFile: (data) => {
        return post('/Captcha/file', data)
    },
    // 获取轮播图列表
    getRotation: (params) => {
        return fetch('/Rotation', params)
    },
    // 获取客户端配置
    getClientConfig: (params) => {
        return fetch('/Client/config', params)
    },
}