/**
 * Show plural label if time is plural number
 * @param {number} time
 * @param {string} label
 * @return {string}
 */
import moment from 'moment'
function pluralize(time, label) {
  if (time === 1) {
    return time + label
  }
  return time + label + 's'
}

/**
 * @param {number} time
 */
export function timeAgo(time) {
  const between = Date.now() / 1000 - Number(time)
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute')
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour')
  } else {
    return pluralize(~~(between / 86400), ' day')
  }
}

export function getLocalTime(value) {
  return moment(value).local().format('YYYY-MM-DD HH:mm:ss')
}

/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */
export function numberFormatter(num, digits) {
  const si = [
    { value: 1e18, symbol: 'E' },
    { value: 1e15, symbol: 'P' },
    { value: 1e12, symbol: 'T' },
    { value: 1e9, symbol: 'G' },
    { value: 1e6, symbol: 'M' },
    { value: 1e3, symbol: 'k' }
  ]
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (
        (num / si[i].value)
          .toFixed(digits)
          .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
      )
    }
  }
  return num.toString()
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num) {
  return (+num || 0)
    .toString()
    .replace(/^-?\d+/g, (m) => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}

/**
 * Upper case first char
 * @param {String} string
 */
export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * dateTime
 * @param {String} string
 */
export function timestampToTime(value) {
  if (value != null) {
    let date = new Date(value)
    let y = date.getFullYear()
    let mon =
      date.getMonth() + 1 >= 10
        ? date.getMonth() + 1
        : '0' + (date.getMonth() + 1)
    let d = date.getDate() >= 10 ? date.getDate() : '0' + date.getDate()
    let h = date.getHours() >= 10 ? date.getHours() : '0' + date.getHours()
    let m =
      date.getMinutes() >= 10 ? date.getMinutes() : '0' + date.getMinutes()
    let s =
      date.getSeconds() >= 10 ? date.getSeconds() : '0' + date.getSeconds()
    return y + '-' + mon + '-' + d + ' ' + h + ':' + m + ':' + s
  }
}
/**
 * date
 * @param {String} string
 */
export function timestampTodate(value) {
  if (value != null) {
    let date = new Date(value)
    let y = date.getFullYear()
    let mon =
      date.getMonth() + 1 >= 10
        ? date.getMonth() + 1
        : '0' + (date.getMonth() + 1)
    let d = date.getDate() >= 10 ? date.getDate() : '0' + date.getDate()

    return y + '-' + mon + '-' + d
  }
}

/**
 * array show
 * @param {String} string
 */
export function showName(arr, Id) {
  if (Id === 'Error') {
    return '错误'
  } else {
    if (arr instanceof Array) {
      let name = ''
      arr.some((item) => {
        if (item.Id === Id) {
          name = item.Name
          return true
        }
      })
      return name
    }
  }
}
