module.exports = {
    common: {
        success: 'success',
        input: 'Please input',
        lang: 'Language',
        comfirm: 'comfirm',
        cancel: 'cancel',
        passwordCheck: 'Password minimum six characters',
        Email: 'Email',
        Phone: 'Phone Number',
        PleaseSerach: 'Please search',
        NickName: 'Nick Name',
        ContactInformation: 'Contact Information',
        Password: 'Password',
        Star: 'star',
        CopySuccess: 'copy success',
        ReferrerID: 'Referrer ID',
        noData: 'No record'
    },
    home: {
        Register: 'Register',
        DirectPush: 'Direct push',
        Audit: 'Audit',
        Service: 'Service',
        MyTeam: 'My team',
        Donate: 'Donate',
    },
    register: {
        Send: 'Send',
        Resend: 'Resend',
        InvitationCode: 'Invitation Code',
        VerificationCode: 'Verification Code',
        btn: 'Register and login',
        Country: 'Country',
        haveAccount: 'Already have an account',
        haveLogin: 'Log in',
        EmailError: 'Email format error'
    },
    login: {
        btn: 'Log in',
        noAccount: 'No account yet',
        toRegister: 'Register'
    },
    user: {
        AllOrders: 'All orders',
        PaymentMethod: 'Collection method',
        ChangePassword: 'Change password',
        ShareLink: 'Share link',
        ApplicationRecord: 'Application record',
        ContactCustomer: 'Contact customer service',
        SignOut: 'Sign out',
        WhetherOut: 'Whether to log out',
        Quit: 'Quit',
        OldPassword: 'Old Password',
        NewPassword: 'New Password',
        confirmChanges: 'Confirm the changes',
        MyShareCode: 'My Share Code',
        CopyShareCode: 'Copy Share Code',
        ShareLimtMsg: 'Please upgrade to 5 stars first'
    },
    pay: {
        Wait: 'Wait',
        Success: 'Success',
        Fail: 'Fail',
        Auto: 'Auto',
        Paid: 'Paid',
        Audited: 'Audited',
        Bank: 'Bank',
        QRCode: 'QR Code',
        TRC20: 'TRC20',
        Gratitude: 'Gratitude fee',
        ToolUsage: 'Tool usage fee',
        AuditMsg: 'Umder Review Please Wait',
        UpgradeMsg: 'The upgrade conditions are not met',
        ReturnHome: 'Return To Home Page',
        GratefulLockMsg: 'Pay gratitude inexplicable lock',
        ApplyMsg: 'Apply for upgrade',
        ApplyMemberMsg: 'member',
        Name: 'Name',
        BankName: 'Bank Name',
        BankAccount: 'Bank Account',
        Channel: 'Channel',
        Address: 'Address',
        SelectQRCode: 'Select collection QR code',
        LeaveWord: 'Leave word',
        UpDocument: 'upload payment document',
        Hubmsg: 'Please confirm that the payment has been completed according to the selected payment method, and confirm the submission after uploading the payment document',
        SubmitApplication: 'Submit Application',
        SubmissionSuccessful: 'Submission Successful',
        PendingReview: 'Pending review',
        UpconfirmMsg: 'Congratulations on successful submission, please wait for review, you can upgrade after confirms payment',
        GratitudeLock: 'Gratitude lock',
        CompleteUnlock: 'Complete the mission to unlock it',
        GratitudeLockMsg: 'Please donate to release the gratitude lock and resume collection',
        ToUnlock: 'To unlock',
        wantDonate: 'I want to donate',
        donationRules: 'voluntary donation rules',
        donationPledge: 'Voluntary donation pledge',
        readVoluntaryDonated: 'I have read and want voluntary donated',
        paymentMethod: 'Collection method',
        TRC20Address: 'TRC20 Address',
        CollectionQRCode: 'Collection QRCode',
        submitInformation: 'submit information',
        AddCollectionQRCode: 'Add Collection QRCode',
        PlatformName: 'Platform Name',
        ConfirmAdd: 'Confirm Add',
        ShareLockMsg: 'You need to have two direct 5 stars in order to continue receiving payments and upgrades',
        TopStarLimtMsg: 'Has reached the highest star rating',
        SetPaymentMsg: 'Please set the Collection method first',
        UpgradeComentMsg1: 'You need to have two direct ',
        UpgradeComentMsg2: 'and there are no pending orders',
    },
    direct: {
        Totalpeople: 'Total people',
        Directpush: 'Direct push',
        starAbove: '1 star and above',
        Statistics: 'Statistics',
        Connections: 'Connections',
        Architecture: 'Architecture',
        MyDirectRecommendation: 'My Direct Recommendation',
        TeamStatistics: 'Team Statistics',
        Ordinarymember: 'Ordinary member',
        starMember: 'star member',
        people: 'people',
        HierarchicalConnections: 'Hierarchical Connections',
        degreeConnections: 'degree connections',
        ArchitectureDiagram: 'Architecture Diagram',
        AllAmount: 'All amount',
        GratitudeAmount: 'Gratitude value',
    },
    audit: {
        Entire: 'Entire',
        Invitation: 'Invitation',
        Gratitude: 'Gratitude',
        Upgrade: 'Upgrade',
        Serachplaceholder: 'Member ID、Nick Name',
        Pass: 'Pass',
        Fail: 'Fail',
        PaymentDetails: 'Payment Details',
        UserID: 'User ID',
        Usernickname: 'User nick name',
        ContactInformation: 'Contact information',
        TransferType: 'Transfer type',
        PaymentAmount: 'Payment amount',
        PaymentTime: 'Payment time',
        PaymentType: 'Payment type',
        PaymentDocument: 'Payment document',
        PaymentRemark: 'Payment remark',
        AuditStatus: 'Audit status',
        AuditTime: 'Audit time',
        ApplicationRecord: 'Application record',
    }
}